body, html {
  padding: 0;
  margin: 0;
  /* font-family: 'Rubik','Helvetica', sans-serif; */
  font-family: "Merriweather Sans", sans-serif;
  background-color: rgb(255, 255, 255);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.pageRoot {
  width: 100%;
  height: calc(100% - 71px);
  overflow: auto;
}

* {
  box-sizing: border-box;
}

footer {
  margin: 40px 0 20px 0;
}

button {
  color: white;
  padding: 7px 10px 6px 10px;
  font-size: 15px;
  border-radius: 5px;
  border: 1px solid black;
  background-color: rgb(2, 117, 216);
}

button:disabled {
  color: #CCC;
  background-color: rgb(76, 109, 139);
}

button:hover, button:focus {
  background-color: rgb(67, 146, 216);
}

button:active {
  background-color: rgb(1, 86, 161);
}

button.secondary {
  color: #000;
  background-color: #CCC;
  margin-left: 20px;
}

button.secondary:disabled {
  color: #999;
  background-color: #AAA;
}

button.secondary:hover, button.secondary:focus {
  background-color: #DDD;
}

button.secondary:active {
  background-color: #AAA;
}

button.inline {
  margin: 0 10px;
  padding: 0 3px;
  font-size: 0.9em;
  border-radius: 4px;
}

#success {
  color: green;
  border: 2px solid green;
  border-radius: 4px;
  padding: 10px;
  background-color: rgba(111, 255, 111, 0.2);
  width: 600px;
}

#errors {
  border: 2px solid red;
  border-radius: 4px;
  padding: 10px;
  background-color: rgba(255, 111, 111, 0.2);
  width: 100%;
  margin-bottom: 20px;
}

#continueButton {
  margin: 20px 0;
}

#content {
  display: flex;
  padding: 20px;
}

#contentLeft {
  width: 620px;
  padding-right: 20px;
}

#contentRight {
  padding-left: 10px;
}

code {
  background-color: #EEEFF1;
  padding: 2px 4px;
  font-size: 16px;
}

.homeBody {
  margin: 20px;
}

label {
  width: 150px;
  display: inline-block;
  text-align: right;
  margin-right: 10px;
}

@media screen and (max-width: 700px) {
  label {
    display: block;
    text-align: left;
  }
}

input {
  width: 250px;
}

input[type="radio"] {
  width: 30px;
}

input[type="file"] {
  display: inline-block;
}

textarea {
  max-width: calc(100% - 40px);
}

pre {
  white-space: break-spaces;
}